import React from "react"
import "./careersMainBanner.css"
// import CareerImage from "../../images/careersBannerImage.png"
import CareerImage from "../../images/careersBannerImage1.webp"
// import CareerVideo from "../../images/careersBannerImage.mp4"

export default function CareerMainBanner() {
  
  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          <img className="career-home-image" alt="" src={CareerImage} />
          {/* <video 
            className="career-home-image"
            src={CareerVideo}
            loop
            autoPlay
            muted
          /> */}
          <div className="career-image-text-box">
            <div className="career-image-background-text">
              <h1 className="career-image-topic">
                Careers
              </h1>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}