import React from "react";
import CareerMainBanner from "../templates/Careers/careersMainBanner";
import CareersSuccessStoryNew from "../templates/Careers/careersSuccessStoryNew";
import CareersLatestVacancy from "../templates/Careers/careersLatestVacancy";
// import Header from "../components/header"
// import Footer from "../components/footer"
import CareersGetTouch from "../templates/Careers/careersGetTouch";

export default function CareersPage() {

  return (
    <div>
      {/* <Header /> */}
      <CareerMainBanner />
      <CareersSuccessStoryNew />
      <CareersLatestVacancy />
      <CareersGetTouch />
      {/* <Footer /> */}
    </div>
  );
}
