import React, { useState, useRef, useEffect } from "react";
import "./careersSuccessStoryNew.css";
import User from "../../images/user.png";
import Ajith from "../../images/Ajith.jpg";
import Sudesh from "../../images/Sudesh.jpg";
import ButtonLeft from "../../images/icon.svg";
import ButtonRight from "../../images/icon1.svg";
import bg_bottom from "../../images/bg_bottom.png";
import bg_top from "../../images/bg_top.png";
import { IoIosCloseCircle } from "react-icons/io";

const successStories = [
  {
    quote:
      "My career at CIC began in January 2006 when I joined as an Accounting Clerk at CIC Seeds Company. Early in my career, I was committed to advancing my skills and knowledge, which led me to successfully complete my Chartered Examinations, earning the qualification as a professional Accountant...",
    readmore:
      "My career at CIC began in January 2006 when I joined as an Accounting Clerk at CIC Seeds Company. Early in my career, I was committed to advancing my skills and knowledge, which led me to successfully complete my Chartered Examinations, earning the qualification as a professional Accountant. <br /><br /> In 2015, CIC implemented the SAP system, a key milestone that marked a significant shift in operations. With my deep understanding of accounting and the new SAP framework, I was entrusted with the role of SAP FICO Consultant in 2017. This position allowed me to leverage my financial expertise along with advanced enterprise solutions, making a meaningful impact on business processes. <br /><br /> After one and a half years of hard work and dedication, I was promoted to Assistant General Manager. This role challenged me to take on broader responsibilities, manage diverse teams, and contribute to strategic decision-making within the organization. <br/><br/> In recent years, CIC took another step forward with the implementation of Power BI, embracing data-driven insights for more effective decision-making. As I contributed to this transformation, I was honored to be a General Manager of CIC Holdings PLC. <br/><br/>Reflecting on my journey, I am grateful for the continuous learning opportunities and the trust the organization placed in me. Each step along the way—from accounting clerk to general manager—has been an exciting chapter in my professional growth, and I look forward to contributing further to CIC’s success in the future.",
    name: "Ajith Madduma",
    position: "IT General Manager",
    avatar: Ajith,
  },
  {
    quote:
      "Working with CIC has been an inspiring experience, surrounded by a supportive team and a positive environment that fosters growth and innovation. I’m grateful for the opportunities and proud to contribute to such an amazing company.<br /><br />",
    name: "Sudesh",
    position: "Finance",
    avatar: Sudesh,
  },
];

const CareersSuccessStoryNew = () => {
  const [activeDirector, setActiveDirector] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoplayActive, setIsAutoplayActive] = useState(true);
  const autoplayRef = useRef(null);

  const openModal = () => {
    setActiveDirector("ReadMore");
    setIsAutoplayActive(false); // Stop autoplay
  };

  const closeModal = () => {
    setActiveDirector(null);
    setIsAutoplayActive(true); // Resume autoplay
  };

  const handleOutsideClick = (event) => {
    if (event.target.className === "success-story-modal") {
      closeModal();
    }
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? successStories.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === successStories.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    if (isAutoplayActive) {
      autoplayRef.current = setInterval(() => {
        handleNextClick();
      }, 4000);
    }

    return () => {
      clearInterval(autoplayRef.current);
    };
  }, [isAutoplayActive]);

  return (
    <div className="success-story">
      <h2
        className="success-title"
        data-aos="fade-down"
        data-aos-duration="500"
      >
        Success Stories
      </h2>
      <div className="slider-container">
        <div className="navigation-buttons">
          <div
            className="button-left"
            onClick={handlePrevClick}
            role="button"
            tabIndex="0"
            data-aos="fade-left"
            data-aos-duration="700"
          >
            <img src={ButtonLeft} alt="Previous" />
          </div>
          <div
            className="button-right"
            onClick={handleNextClick}
            role="button"
            tabIndex="0"
            data-aos="fade-right"
            data-aos-duration="700"
          >
            <img src={ButtonRight} alt="Next" />
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="500"
          className="success-card"
        >
          <div className="success-content">
            <div className="profile-section">
              <div className="profile-outer">
                <img
                  src={successStories[currentIndex].avatar}
                  alt="Person"
                  className="profile-image"
                />
              </div>
            </div>
            <div className="quote-icon top">“</div>
            <p
              className="success-text"
              dangerouslySetInnerHTML={{
                __html: successStories[currentIndex].quote,
              }}
            ></p>
            <div className="success-story-container">
              {successStories[currentIndex].readmore && (
                <span onClick={openModal} className="success-story-read-more">
                  Read More
                </span>
              )}
            </div>
            <div>
              {!successStories[currentIndex].readmore && (
                <div className="quote-icon bottom">”</div>
              )}
            </div>
          </div>
          <div className="card-footer">
            <div className="success-author">
              <strong>{successStories[currentIndex].name}</strong>
              <p>{successStories[currentIndex].position}</p>
            </div>
          </div>
        </div>
      </div>
      {activeDirector === "ReadMore" && (
        <div
          className="success-story-modal"
          onClick={(e) => handleOutsideClick(e)}
        >
          <div className="success-story-model-left">
            <div className="success-story-model-left-image-container">
              <img src={successStories[currentIndex].avatar} alt="" />
            </div>
          </div>
          <div className="success-story-model-right">
            <div className="pop-up-close-btn" onClick={closeModal}>
              <IoIosCloseCircle color="#001033" size={30} />
            </div>
            <div className="success-story-model-left-bg-top-image-container">
              <img src={bg_top} alt="" />
            </div>
            <div className="success-story-model-left-bg-bottom-image-container">
              <img src={bg_bottom} alt="" />
            </div>
            <div className="success-story-model-right-content">
              <h2 className="success-story-modal-content-heading">
                {successStories[currentIndex].name}
              </h2>
              <p
                className="success-story-modal-content-paragraph"
                dangerouslySetInnerHTML={{
                  __html: successStories[currentIndex].readmore,
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="dots-container">
        {successStories.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default CareersSuccessStoryNew;
